<template>
  <div class="flex min-h-screen flex-col overflow-x-hidden bg-gray-50 font-sans text-gray-600 antialiased">
    <header class="flex items-center p-6">
      <VeezuLogo class="h-4 w-auto" />
    </header>

    <div class="flex flex-1 flex-col items-center justify-center text-center">
      <h1 class="text-xl font-semibold text-red-500">
        {{ error?.statusCode }} - {{ $t(`error.status.${error?.statusCode}`, $t('error.status.default')) }}
      </h1>
      <p class="mt-2 max-w-md text-sm">
        {{ $t(`error.message.${error?.statusCode}`, $t('error.message.default')) }}
      </p>
      <BaseButton class="mt-6" @click="home">{{ $t('error.errHomeButton') }}</BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

withDefaults(
  defineProps<{
    error: NuxtError
  }>(),
  {
    error: () => ({}),
  }
)

function home() {
  return navigateTo('/documents?filter[status]=pending')
}
</script>
