<template>
  <span :class="styles" class="inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-xs font-medium">
    <slot />
  </span>
</template>

<script lang="ts" setup>
const props = defineProps<{
  color?: 'primary' | 'secondary' | 'outline' | 'danger' | 'success' | 'warning'
}>()

const styles = computed<string[]>(() => {
  const colors = {
    primary: 'bg-gray-950 text-white',
    secondary: 'bg-gray-100 text-gray-950',
    outline: 'border border-gray-200 text-gray-950',
    danger: 'bg-red-500 text-white',
    success: 'bg-emerald-500 text-white',
    warning: 'bg-amber-500 text-white',
  }

  return [colors[props.color ?? 'primary']]
})
</script>
